// Input.js

import React from 'react';
import Box from '../box';
import Typography from '../typography';
import Row from '../row';
import Col from '../col';
import Button from '../core/button';

function NewsLetter({
  icon,
  title,
  subtitle,
  label,
  value,
  onChange,
  ...restProps
}) {
  return (
    <Box marginTop={4}>
      <Row noSpace alignItems="center">
        <Col md={9} sm={8} sx={{ pb: { xs: 4 } }}>
          <Typography variant="h4">
            Subscribe to our newsletter by providing your email address or opt
            to receive updates via text message on your mobile phone.
          </Typography>
        </Col>
        <Col md={3} sm={4} className="text-end">
          <Button
            size="large"
            color="white"
            sx={{
              border: '3px solid #4D8E7E',
              color: '#4D8E7E',
              fontSize: '1.2rem',
              borderRadius: 8,
              pl: { md: 8, sm: 4 },
              pr: { md: 8, sm: 4 },
            }}
          >
            Subscribe
          </Button>
        </Col>
      </Row>
    </Box>
  );
}

export default NewsLetter;
