import {
  CategoryOutlined,
  ExpandMore,
  PersonOutline,
} from '@mui/icons-material';
import MenuIcon from '@mui/icons-material/Menu';
import AppBar from '@mui/material/AppBar';
import Divider from '@mui/material/Divider';
import Drawer from '@mui/material/Drawer';
import IconButton from '@mui/material/IconButton';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Toolbar from '@mui/material/Toolbar';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import SavyomoLogo from '../../assets/images/savyomo-logo.svg';
import { getAllBusinessLines } from '../../module/auth/services/authAPI';
import ROUTES from '../../utils/constant/routePaths';
import { clearAuthInfo } from '../../utils/helper/authTokenStorage';
import Box from '../box';
import Button from '../core/button';
import { Tooltip, Typography } from '@mui/material';
import ListSubheader from '@mui/material/ListSubheader';

const pathObj = {
  [ROUTES.USER.RESTAURANTS.CUISINE_PLAN]: 'Restaurants',
  [ROUTES.USER.AUTO_MOTIVE.AUTO_SERVICE_PLAN]: 'Automotive Services',
  [ROUTES.MERCHANT.RESTAURANT_ADVERTISEMENT]: 'Restaurants',
  [ROUTES.MERCHANT.AUTO_MOTIVE_ADVERTISEMENT]: 'Automotive Services',
};

function Header({ window }) {
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const userInfo = useSelector((state) => state.auth.userData);

  const [navItems, setNavItems] = useState([]);
  const [anchorEl, setAnchorEl] = useState(null);
  const [anchorProfile, setAnchorProfile] = useState(null);
  const [mobileOpen, setMobileOpen] = useState(false);
  const [businessLines, setBusinessLines] = useState([]);
  const [selectedBusinessLine, setSelectedBusinessLine] = useState(
    pathObj[pathname] || ''
  );

  const open = Boolean(anchorEl);
  const openProfile = Boolean(anchorProfile);
  const container = window ? () => window().document.body : undefined;

  const handleDrawerToggle = () => {
    setMobileOpen((prevState) => !prevState);
  };

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleProfile = (event) => {
    setAnchorProfile(event.currentTarget);
  };

  const handleCloseProfile = () => {
    setAnchorProfile(null);
  };

  const handleSignOut = () => {
    const isProvider = userInfo?.role === 'provider';
    setAnchorProfile(null);
    clearAuthInfo();
    navigate(isProvider ? ROUTES.MERCHANT.LOGIN : ROUTES.USER.LOGIN);
  };

  const handleMenuItemClick = (name) => {
    setSelectedBusinessLine(name);
    setAnchorEl(null);
    const isProvider = userInfo?.role === 'provider';
    if (name === 'Restaurants') {
      navigate(
        isProvider
          ? ROUTES.MERCHANT.RESTAURANT_ADVERTISEMENT
          : ROUTES.USER.RESTAURANTS.CUISINE_PLAN
      );
    } else if (name === 'Automotive Services') {
      navigate(
        isProvider
          ? ROUTES.MERCHANT.AUTO_MOTIVE_ADVERTISEMENT
          : ROUTES.USER.AUTO_MOTIVE.AUTO_SERVICE_PLAN
      );
    }
  };

  const handleChangePassword = () => {
    navigate(ROUTES.USER.FORGOT_PASSWORD);
  };

  useEffect(() => {
    setNavItems([userInfo?.name]);
    getAllBusinessLines()
      .then((res) => {
        if (res.businessLines) setBusinessLines(res.businessLines);
      })
      .catch(() => {});
  }, []);

  const drawer = (
    <Box onClick={handleDrawerToggle} sx={{ textAlign: 'center' }}>
      <Box className="m-1">
        <img
          src={SavyomoLogo}
          width={200}
          alt="savyomo-logo"
          style={{ cursor: 'pointer' }}
        />
      </Box>
      <Divider />

      <List
        sx={{ width: '100%' }}
        subheader={
          <ListSubheader component="h6" id="nested-list-subheader">
            Category
          </ListSubheader>
        }
      >
        {navItems.map((item) => (
          <>
            {businessLines.map(({ name, key, active }) => (
              <ListItem key={item} disablePadding>
                <ListItemButton
                  sx={{ textAlign: 'center' }}
                  key={key}
                  onClose={() => {
                    setAnchorEl(null);
                  }}
                  disabled={
                    userInfo.role === 'provider'
                      ? key !== userInfo.businessLine.key
                      : !active
                  }
                  selected={selectedBusinessLine === name}
                  onClick={() => handleMenuItemClick(name)}
                >
                  {/* {selectedBusinessLine === name.restaurants ? <Restaurant/>: <Garage />} */}
                  {name}
                </ListItemButton>
              </ListItem>
            ))}
          </>
          //   <ListItemButton sx={{ textAlign: 'center' }}>
          //     <ListItemText primary={item} />
          //   </ListItemButton> */}
          // </ListItem>
        ))}
      </List>
    </Box>
  );

  return (
    <Box sx={{ fontFamily: 'alef' }}>
      <Box sx={{ display: 'flex', alignItems: 'center' }}>
        <AppBar component="nav" color="white">
          <Toolbar sx={{ margin: { lg: '0 40px', padding: { sm: '0' } } }}>
            <IconButton
              color="secondary"
              aria-label="open drawer"
              edge="start"
              onClick={handleDrawerToggle}
              sx={{ mr: 2, display: { sm: 'none' } }}
            >
              <MenuIcon />
            </IconButton>
            <Box
              sx={{ flexGrow: 1, display: { sm: 'block' } }}
              onClick={() => {
                if (userInfo?.role === 'consumer') {
                  navigate(ROUTES.USER.DASHBOARD);
                }
              }}
            >
              <img
                src={SavyomoLogo}
                width={200}
                alt="savyomo-logo"
                style={{ cursor: 'pointer' }}
              />
            </Box>
            <Box sx={{ flexGrow: 1, display: { xs: 'none', sm: 'flex' } }}>
              <Button
                id="basic-button"
                aria-controls={open ? 'basic-menu' : undefined}
                aria-haspopup="true"
                sx={{ color: '#464646', fontSize: '1.1rem' }}
                variant="text"
                aria-expanded={open ? 'true' : undefined}
                onClick={handleClick}
              >
                <CategoryOutlined /> &nbsp;
                {selectedBusinessLine || 'Categories'}
                <ExpandMore />
              </Button>
              <Menu
                id="category-menu"
                anchorEl={anchorEl}
                open={open}
                onClose={() => {
                  setAnchorEl(null);
                }}
                MenuListProps={{
                  'aria-labelledby': 'basic-button',
                }}
              >
                {businessLines.map(({ name, key, active }) => (
                  <MenuItem
                    key={key}
                    onClose={() => {
                      setAnchorEl(null);
                    }}
                    disabled={
                      userInfo.role === 'provider'
                        ? key !== userInfo.businessLine.key
                        : !active
                    }
                    selected={selectedBusinessLine === name}
                    onClick={() => handleMenuItemClick(name)}
                  >
                    {name}
                  </MenuItem>
                ))}
              </Menu>
            </Box>
            <Box sx={{ display: { sm: 'block' } }}>
              <Tooltip title={<Typography>{userInfo?.email}</Typography>}>
                <span>
                  {' '}
                  <Button
                    id="profile-menu"
                    aria-controls={open ? 'profile-menu' : undefined}
                    aria-haspopup="true"
                    sx={{ color: '#464646', fontSize: '1.2rem', padding: '0' }}
                    variant="text"
                    aria-expanded={open ? 'true' : undefined}
                    onClick={handleProfile}
                  >
                    <PersonOutline /> &nbsp;
                    {userInfo?.name}
                  </Button>
                </span>
              </Tooltip>

              <Menu
                id="profile-menu"
                anchorEl={anchorProfile}
                open={openProfile}
                onClose={handleCloseProfile}
                MenuListProps={{
                  'aria-labelledby': 'profile-menu',
                }}
              >
                <MenuItem onClick={handleChangePassword}>
                  Change Password
                </MenuItem>
                <MenuItem onClick={handleSignOut}>Sign Out</MenuItem>
              </Menu>
            </Box>
          </Toolbar>
        </AppBar>
        <nav>
          <Drawer
            container={container}
            variant="temporary"
            open={mobileOpen}
            onClose={handleDrawerToggle}
            ModalProps={{
              keepMounted: true,
            }}
            sx={{
              display: { xs: 'block', md: 'none' },
              '& .MuiDrawer-paper': {
                boxSizing: 'border-box',
                width: 240,
              },
            }}
          >
            {drawer}
          </Drawer>
        </nav>
        {/* <Box component="main">
          <Box sx={{ display: { xs: 'block', sm: 'none' } }}>
            {navItems.map((item, index) => (
              
                <Button
                  key={item}
                  id="profile-menu"
                  aria-controls={open ? 'profile-menu' : undefined}
                  aria-haspopup="true"
                  sx={{ color: '#464646', fontSize: '1.2rem' }}
                  variant="text"
                  aria-expanded={open ? 'true' : undefined}
                  onClick={handleProfile}
                >
                  <PersonOutline />
                  {item}
                </Button>
              
            ))}
            <Menu
              id="profile-menu"
              anchorEl={anchorProfile}
              open={openProfile}
              onClose={handleCloseProfile}
              MenuListProps={{
                'aria-labelledby': 'profile-menu',
              }}
            >
              <MenuItem onClick={handleSignOut}>Change Password</MenuItem>
              <MenuItem onClick={handleSignOut}>Sign Out</MenuItem>
            </Menu>
          </Box>
        </Box> */}
      </Box>
    </Box>
  );
}

export default Header;
