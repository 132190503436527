import AddIcon from '@mui/icons-material/Add';
import {
  Card,
  CardActions,
  CardContent,
  Grid,
  TableContainer,
} from '@mui/material';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Typography from '@mui/material/Typography';
import dayjs from 'dayjs';
import { enqueueSnackbar } from 'notistack';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import Container from '../../../components/container';
import Button from '../../../components/core/button';
import Footer from '../../../components/footer';
import Header from '../../../components/header';
import { getAutoMotiveServices } from '../../auto-motive/services/autoServiceApi';
import {
  deletePaymentMethod,
  getAttachedCards,
} from '../../payment/services/paymentApi';
import MultipleDayAdvertisement from '../components/MultipleDayAdvertisement';
import SingleDayAdvertisement from '../components/SingleDayAdvertisement';
import SuccessPopup from '../components/SuccessPopup';
import {
  createAutomotiveAdvertisement,
  getAutomotiveAdvertisements,
} from '../services/merchantApi';

function AutomotiveAdvertisement() {
  const userInfo = useSelector((state) => state.auth.userData);

  const [automotiveServices, setAutomotiveServices] = useState([]);
  const [showOneDayPopup, setShowOneDayPopup] = useState(false);
  const [showMultiDayPopup, setShowMultiDayPopup] = useState(false);
  const [singleDayAdvertisement, setSingleDayAdvertisement] = useState([]);
  const [multipleDayAdvertisement, setMultipleDayAdvertisement] = useState([]);
  const [paymentMethods, setPaymentMethods] = useState([]);
  const [showSuccessPopup, setShowSuccessPopup] = useState(false);

  const fetchAdvertisements = (type) => {
    const { provider: { _id: providerId } = {} } = userInfo;
    getAutomotiveAdvertisements(providerId, type)
      .then((res) => {
        type === 'SINGLE_DAY'
          ? setSingleDayAdvertisement([...res])
          : setMultipleDayAdvertisement([...res]);
      })
      .catch((err) => err);
  };

  const fetchCards = () => {
    getAttachedCards(userInfo.customerId)
      .then((res) => {
        const { data } = res;
        if (data) setPaymentMethods([...data]);
      })
      .catch((err) => err);
  };

  const deleteCard = (paymentMethodId) => {
    deletePaymentMethod(paymentMethodId).then((res) => {
      if (res.status === 200) {
        enqueueSnackbar(res.message, {
          variant: 'success',
        });
        fetchCards();
      }
    });
  };

  const addAdvertisement = async (providerId, reqData, singleDay) => {
    return new Promise((resolve) => {
      createAutomotiveAdvertisement(providerId, reqData).then((res) => {
        const { statusCode, message } = res;
        enqueueSnackbar(message, {
          variant: statusCode === 200 ? 'success' : 'error',
        });
        if (statusCode === 200) {
          if (singleDay) {
            setShowOneDayPopup(false);
            fetchAdvertisements('SINGLE_DAY');
            setShowSuccessPopup(true);
          } else {
            setShowMultiDayPopup(false);
            fetchAdvertisements('MULTIPLE_DAY');
          }
        }
        resolve(statusCode === 200);
      });
    });
  };

  const fetchAutoMotiveServices = () => {
    getAutoMotiveServices()
      .then((res) => {
        setAutomotiveServices(res.autoServices);
      })
      .catch((err) => err);
  };

  useEffect(() => {
    fetchAutoMotiveServices();
    fetchCards();
    fetchAdvertisements('MULTIPLE_DAY');
    fetchAdvertisements('SINGLE_DAY');
  }, []);

  return (
    <Container>
      <Header />
      <Grid container padding={1} justifyContent="center">
        <Grid item marginTop={{ lg: 15, xs: 5 }}>
          <Typography variant="h3" fontWeight="bold" color="#4F8D7D">
            Be the Talk of the Town for Just $1
          </Typography>
        </Grid>
        <Grid item xs={12} lg={9}>
          <Grid
            container
            spacing={2}
            justifyContent="center"
            marginTop={5}
            marginBottom={5}
          >
            <Grid item xs={12} sm={6}>
              <Typography
                variant="h3"
                fontSize={'1.9rem'}
                fontWeight="bold"
                marginBottom={2}
              >
                Why go for one-day ads?
              </Typography>
              <Card
                sx={{
                  backgroundColor: '#98C2B9',
                  color: '#fff',
                }}
              >
                <CardContent
                  sx={{ padding: { xl: 3, xs: 2 } }}
                  className="one-day-advertisement-box"
                >
                  <Typography fontSize="18px" lineHeight={1.8}>
                    Enhance workforce productivity and daily revenue by offering
                    regular promotions for your automotive business. This
                    strategy fosters consistent customer engagement and
                    sustainable revenue growth, moving beyond dependence on
                    holiday campaigns.
                  </Typography>
                </CardContent>
                <CardActions sx={{ padding: 3 }}>
                  <Button
                    color="light"
                    sx={{ color: '#4F8D7D', backgroundColor: '#fff' }}
                    onClick={() => {
                      setShowOneDayPopup(true);
                    }}
                  >
                    <AddIcon /> Create One day advertisement
                  </Button>
                </CardActions>
              </Card>
            </Grid>
            <Grid item xs={12} sm={6} mt={{ sm: 0, xs: 2 }}>
              <Typography
                variant="h3"
                fontSize={'1.9rem'}
                fontWeight="bold"
                marginBottom={2}
              >
                Why go for multi-day ads?
              </Typography>
              <Card
                sx={{
                  backgroundColor: '#98C2B9',
                  color: '#fff',
                }}
              >
                <CardContent
                  sx={{ padding: 3 }}
                  className="multiple-day-advertisement-box"
                >
                  <Typography lineHeight={1.8} fontSize="18px">
                    Grow your customer base and boost revenue with extended
                    promotions on your auto services. Longer-term strategies
                    drive consistent engagement, increasing both profitability
                    and financial gains.
                  </Typography>
                </CardContent>
                <CardActions sx={{ padding: 3, paddingTop: { xl: 7, sm: 5 } }}>
                  <Button
                    color="light"
                    sx={{ color: '#4F8D7D', backgroundColor: '#fff' }}
                    onClick={() => {
                      setShowMultiDayPopup(true);
                    }}
                  >
                    <AddIcon /> Create Multi days advertisement
                  </Button>
                </CardActions>
              </Card>
            </Grid>
          </Grid>
        </Grid>
      </Grid>

      <SuccessPopup
        showSuccessPopup={showSuccessPopup}
        handleClose={() => {
          setShowSuccessPopup(false);
        }}
      />

      <Grid
        container
        spacing={2}
        justifyContent="center"
        marginTop={5}
        marginBottom={10}
        paddingTop={5}
        paddingBottom={7}
        className="bg-restaurant"
        pl={{ lg: 0, xs: 1 }}
        pr={1}
      >
        <Grid item xs={12} lg={9}>
          <SingleDayAdvertisement
            type="automotive"
            categories={automotiveServices}
            paymentMethods={paymentMethods}
            open={showOneDayPopup}
            handleClose={() => {
              setShowOneDayPopup(false);
            }}
            deleteCard={deleteCard}
            setPaymentMethods={setPaymentMethods}
            addAdvertisement={addAdvertisement}
          />
          <MultipleDayAdvertisement
            type="automotive"
            categories={automotiveServices}
            paymentMethods={paymentMethods}
            open={showMultiDayPopup}
            handleClose={() => {
              setShowMultiDayPopup(false);
            }}
            deleteCard={deleteCard}
            setPaymentMethods={setPaymentMethods}
            addAdvertisement={addAdvertisement}
          />

          {/* One day Advertisement */}
          <Card sx={{ mb: 5 }}>
            <CardContent>
              <Typography
                variant="h4"
                textAlign="center"
                color="#4F8D7D"
                marginBottom={3}
                fontWeight="bold"
              >
                One Day Advertisement
              </Typography>
              <TableContainer
                // sx={{ minWidth: 650, maxHeight: '400px', overflowY: 'auto' }}
                sx={{
                  minWidth: {
                    lg: 650,
                    maxHeight: '400px',
                    overflowY: 'auto',
                    borderCollapse: 'separate',
                    tableLayout: 'fixed',
                  },
                }}
              >
                <Table
                  component="table"
                  sx={{
                    borderCollapse: 'separate',
                    tableLayout: 'fixed',
                  }}
                  padding="normal"
                >
                  <TableHead>
                    <TableRow>
                      <TableCell sx={{ color: '#4F8D7D', fontSize: '16px' }}>
                        Offer Name
                      </TableCell>
                      <TableCell sx={{ color: '#4F8D7D', fontSize: '16px' }}>
                        Category Name
                      </TableCell>
                      <TableCell sx={{ color: '#4F8D7D', fontSize: '16px' }}>
                        Date
                      </TableCell>
                      <TableCell sx={{ color: '#4F8D7D', fontSize: '16px' }}>
                        Quantity
                      </TableCell>
                      <TableCell sx={{ color: '#4F8D7D', fontSize: '16px' }}>
                        Paid Amount
                      </TableCell>
                      <TableCell />
                    </TableRow>
                  </TableHead>
                  {singleDayAdvertisement?.length ? (
                    <TableBody>
                      {singleDayAdvertisement.map(
                        (
                          {
                            name,
                            service,
                            timezone,
                            durationFrom,
                            durationTo,
                            advertisementCost,
                            processingFee,
                            quantity,
                          },
                          index
                        ) => (
                          <TableRow
                            key={index + 1}
                            sx={{
                              '&:last-child td, &:last-child th': { border: 0 },
                            }}
                          >
                            <TableCell component="th" scope="row">
                              {name}
                            </TableCell>
                            <TableCell component="th" scope="row">
                              {service.name}
                            </TableCell>
                            <TableCell>
                              {dayjs(durationFrom)
                                .tz(timezone)
                                .format('MMMM DD, YYYY')}
                            </TableCell>
                            <TableCell>{quantity}</TableCell>
                            <TableCell>
                              ${advertisementCost + processingFee}
                            </TableCell>
                            <TableCell>
                              <Typography color={'red'}>
                                {dayjs(durationTo).isBefore(dayjs.utc()) &&
                                  'Expired'}
                              </Typography>
                            </TableCell>
                          </TableRow>
                        )
                      )}
                    </TableBody>
                  ) : (
                    <TableBody>
                      <TableRow
                        sx={{
                          '&:last-child td, &:last-child th': { border: 0 },
                        }}
                      >
                        <TableCell colSpan={5} align="center">
                          <Typography component={'h6'}>
                            No records found.
                          </Typography>
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  )}
                </Table>
              </TableContainer>
            </CardContent>
          </Card>

          {/* Multi day Advertisement */}
          <Card>
            <CardContent>
              <Typography
                variant="h4"
                textAlign="center"
                color="#4F8D7D"
                marginBottom={3}
                fontWeight="bold"
              >
                Multi Days Advertisement
              </Typography>
              <TableContainer
                sx={{
                  minWidth: {
                    lg: 650,
                    maxHeight: '400px',
                    overflowY: 'auto',
                    borderCollapse: 'separate',
                    tableLayout: 'fixed',
                  },
                }}
              >
                <Table
                  sx={{
                    borderCollapse: 'separate',
                    tableLayout: 'fixed',
                  }}
                  padding="none"
                >
                  <TableHead>
                    <TableRow>
                      <TableCell sx={{ color: '#4F8D7D', fontSize: '16px' }}>
                        Offer Name
                      </TableCell>
                      <TableCell sx={{ color: '#4F8D7D', fontSize: '16px' }}>
                        Category Name
                      </TableCell>
                      <TableCell sx={{ color: '#4F8D7D', fontSize: '16px' }}>
                        From Date
                      </TableCell>
                      <TableCell sx={{ color: '#4F8D7D', fontSize: '16px' }}>
                        To Date
                      </TableCell>
                      <TableCell sx={{ color: '#4F8D7D', fontSize: '16px' }}>
                        Paid Amount
                      </TableCell>
                      <TableCell />
                    </TableRow>
                  </TableHead>
                  {multipleDayAdvertisement?.length ? (
                    <TableBody>
                      {multipleDayAdvertisement.map(
                        (
                          {
                            name,
                            service,
                            timezone,
                            durationFrom,
                            durationTo,
                            advertisementCost,
                            processingFee,
                          },
                          index
                        ) => (
                          <TableRow
                            key={index + 1}
                            sx={{
                              '&:last-child td, &:last-child th': { border: 0 },
                            }}
                          >
                            <TableCell component="th" scope="row">
                              {name}
                            </TableCell>
                            <TableCell component="th" scope="row">
                              {service.name}
                            </TableCell>

                            <TableCell>
                              {dayjs(durationFrom)
                                .tz(timezone)
                                .format('MMMM DD, YYYY')}
                            </TableCell>
                            <TableCell>
                              {dayjs(durationTo)
                                .tz(timezone)
                                .format('MMMM DD, YYYY')}
                            </TableCell>
                            <TableCell>
                              ${advertisementCost + processingFee}
                            </TableCell>
                            <TableCell>
                              <Typography color={'red'}>
                                {dayjs(durationTo).isBefore(dayjs.utc()) &&
                                  'Expired'}
                              </Typography>
                            </TableCell>
                          </TableRow>
                        )
                      )}
                    </TableBody>
                  ) : (
                    <TableBody>
                      <TableRow
                        sx={{
                          '&:last-child td, &:last-child th': { border: 0 },
                        }}
                      >
                        <TableCell colSpan={5} align="center">
                          <Typography component={'h6'}>
                            No records found.
                          </Typography>
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  )}
                </Table>
              </TableContainer>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
      <Footer />
    </Container>
  );
}

export default AutomotiveAdvertisement;
