import AddIcon from '@mui/icons-material/Add';
import CloseIcon from '@mui/icons-material/Close';
import { Card, CardContent, Grid } from '@mui/material';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Typography from '@mui/material/Typography';
import dayjs from 'dayjs';
import { enqueueSnackbar } from 'notistack';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import Container from '../../../components/container';
import Button from '../../../components/core/button';
import Footer from '../../../components/footer';
import Header from '../../../components/header';
import AddCuisinePlan from '../components/AddCuisinePlan';
import {
  deleteRestaurantServiceRequest,
  fetchRestaurantServiceRequest,
  getRestaurantsCuisines,
} from '../services/cuisineApi';

function CuisinePlan() {
  const userInfo = useSelector((state) => state.auth.userData);

  const [open, setOpen] = useState(false);
  const [cuisines, setCuisines] = useState([]);
  const [cuisinePlans, setCuisinePlans] = useState([]);

  const getCuisinePlans = async () => {
    fetchRestaurantServiceRequest(userInfo._id).then((res) => {
      const plans = res?.map(
        ({ _id, cuisine, durationFrom, durationTo, timezone, zipCode }) => ({
          id: _id,
          name: cuisine.name,
          fromDate: dayjs(durationFrom).tz(timezone).format('MMMM DD, YYYY'),
          toDate: dayjs(durationTo).tz(timezone).format('MMMM DD, YYYY'),
          zipCode,
        })
      );
      setCuisinePlans([...plans]);
    });
  };

  useEffect(() => {
    getCuisinePlans();
    getRestaurantsCuisines()
      .then((res) => {
        setCuisines(res.cuisines);
      })
      .catch((err) => err);
  }, []);

  const deletePlan = (id) => {
    deleteRestaurantServiceRequest(userInfo._id, id).then(async (res) => {
      if (res.statusCode === 200) {
        await getCuisinePlans();
        enqueueSnackbar(res.message, { variant: 'success' });
      }
    });
  };

  return (
    <Container className="bg-restaurant">
      <Header />
      <Grid
        container
        spacing={2}
        justifyContent="center"
        marginTop={15}
        marginBottom={10}
      >
        <Grid item xs={12} sm={11} lg={9}>
          <AddCuisinePlan
            cuisines={cuisines}
            open={open}
            handleClose={() => {
              setOpen(false);
            }}
            getCuisinePlans={getCuisinePlans}
          />
          <Card sx={{ margin: { xs: 2 } }}>
            <CardContent>
              <Grid container spacing={2}>
                <Grid item xs={6}>
                  <Typography variant="h4" color="#4F8D7D" marginBottom={5}>
                    My Cuisine Plans
                  </Typography>
                </Grid>
                <Grid item xs={6} textAlign="right">
                  <Button
                    color="primary"
                    onClick={() => {
                      setOpen(true);
                    }}
                    disabled={cuisinePlans.length >= 3}
                  >
                    <AddIcon /> Add Cuisine Plan
                  </Button>
                </Grid>
              </Grid>
              <Table
                sx={{
                  minWidth: {
                    lg: 650,
                    borderCollapse: 'separate',
                    tableLayout: 'fixed',
                  },
                }}
              >
                <TableHead>
                  <TableRow>
                    <TableCell>Cuisine</TableCell>
                    <TableCell align="center">From Date</TableCell>
                    <TableCell align="center">To Date</TableCell>
                    <TableCell align="center">
                      {/* Distance From Restaurant */}
                      Zip Code
                    </TableCell>
                    <TableCell align="center"></TableCell>
                  </TableRow>
                </TableHead>
                {cuisinePlans.length ? (
                  <TableBody>
                    {cuisinePlans.map(
                      ({ id, name, fromDate, toDate, zipCode }) => (
                        <TableRow
                          key={name}
                          sx={{
                            '&:last-child td, &:last-child th': { border: 0 },
                          }}
                        >
                          <TableCell component="th" scope="row">
                            {name}
                          </TableCell>
                          <TableCell align="center">{fromDate}</TableCell>
                          <TableCell align="center">{toDate}</TableCell>
                          {/* <TableCell align="center">{distance} miles</TableCell> */}
                          <TableCell align="center">{zipCode}</TableCell>
                          <TableCell align="center">
                            <Button
                              color="error"
                              variant="text"
                              onClick={() => deletePlan(id)}
                            >
                              <CloseIcon />
                            </Button>
                          </TableCell>
                        </TableRow>
                      )
                    )}
                  </TableBody>
                ) : (
                  <TableBody>
                    <TableRow
                      sx={{
                        '&:last-child td, &:last-child th': { border: 0 },
                      }}
                    >
                      <TableCell colSpan={4} align="center">
                        <Typography component={'h6'}>
                          No records found.
                        </Typography>
                      </TableCell>
                    </TableRow>
                  </TableBody>
                )}
              </Table>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
      <Footer />
    </Container>
  );
}

export default CuisinePlan;
