import React from 'react';
import CloseIcon from '@mui/icons-material/Close';
import { Dialog, DialogContent, DialogTitle, Grid } from '@mui/material';
import Typography from '@mui/material/Typography';
import Button from '../../../components/core/button';

function SuccessPopup({ showSuccessPopup, handleClose }) {
  return (
    <Dialog open={showSuccessPopup} onClose={handleClose}>
      <DialogTitle id="responsive-dialog-title">
        <Grid container>
          <Grid item xs={10} sm={10}>
            <Typography variant="h6" color="#4F8D7D">
              !! IMPORTANT MESSAGE !!
            </Typography>
          </Grid>
          <Grid item xs={2} sm={2} textAlign="right">
            <Button variant="text" onClick={handleClose}>
              <CloseIcon />
            </Button>
          </Grid>
        </Grid>
      </DialogTitle>
      <DialogContent>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={12}>
            <Typography>
              Thank you for creating a one-day offer. Please check your email
              for verification codes that can be used for verification purposes
              when customers visit your store.
            </Typography>
          </Grid>
        </Grid>
      </DialogContent>
    </Dialog>
  );
}

export default SuccessPopup;
