import { createTheme } from '@mui/material';

export default createTheme({
  palette: {
    primary: {
      main: '#98C2B9',
    },
    secondary: {
      main: '#464646',
    },
    light: {
      main: '#e0e0e0',
    },
    white: {
      main: '#fff',
    },
    lightGreen: {
      main: '#98C2B9',
    },
    dark: {
      main: 'rgba(0, 0, 0, 0.87)',
    },
  },
  typography: {
    fontFamily: "'Poppins', sans-serif",
    h3: {
      // fontSize: 2.5,
      '@media (max-width:1199px)': {
        fontSize: '2rem',
      },
      '@media (max-width:991px)': {
        fontSize: '1.5rem',
      },
      '@media (max-width:767px)': {
        fontSize: '1.5rem',
      },
    },
    h4: {
      fontSize: '2rem',
      '@media (max-width:1199px)': {
        fontSize: '1.4rem',
      },
      '@media (max-width:767px)': {
        fontSize: '1.2rem',
      },
    },
    h5: {
      // fontSize: 2.5,
      '@media (max-width:1199px)': {
        fontSize: '1.2rem',
      },
      '@media (max-width:767px)': {
        fontSize: '1rem',
      },
    },
    h6: {
      // fontSize: 2.5,
      '@media (max-width:1199px)': {
        fontSize: '1.2rem',
      },
      '@media (max-width:767px)': {
        fontSize: '0.9rem',
      },
    },
  },
  MuiButton: {
    styleOverrides: {
      fontSize: '1rem',
      root: {
        fontSize: '1rem',
      },
    },
  },
});
