import { Navigate, Outlet, useLocation } from 'react-router-dom';
import AuthLayout from '../layouts/AuthLayout';
import { getAuthInfo } from '../module/auth/services/authAPI';
import ROUTES from '../utils/constant/routePaths';
import { CONSUMER } from '../utils/constant/misc';

function PublicRoute() {
  const { isAuthenticated, userRole, provider } = getAuthInfo();
  const location = useLocation();

  return isAuthenticated &&
    ROUTES.USER.FORGOT_PASSWORD !== location.pathname ? (
    <Navigate
      to={
        userRole === CONSUMER
          ? ROUTES.USER.DASHBOARD
          : provider === 'restaurants'
            ? ROUTES.MERCHANT.RESTAURANT_ADVERTISEMENT
            : ROUTES.MERCHANT.AUTO_MOTIVE_ADVERTISEMENT
      }
    />
  ) : (
    <AuthLayout>
      <Outlet />
    </AuthLayout>
  );
}

export default PublicRoute;
