import { yupResolver } from '@hookform/resolvers/yup';
import AddIcon from '@mui/icons-material/Add';
import CloseIcon from '@mui/icons-material/Close';
import DeleteIcon from '@mui/icons-material/Delete';
import {
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormControlLabel,
  FormLabel,
  Grid,
  IconButton,
  InputAdornment,
  InputLabel,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  Typography,
} from '@mui/material';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { loadStripe } from '@stripe/stripe-js';
import dayjs from 'dayjs';
import { enqueueSnackbar } from 'notistack';
import React, { useEffect, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useSelector } from 'react-redux';
import * as yup from 'yup';
import Button from '../../../components/core/button';
import TextInputControl from '../../../components/core/input/TextInputControl';
import CheckoutForm from '../../payment/components/CheckoutForm';
import {
  confirmPayment,
  createPaymentIntent,
} from '../../payment/services/paymentApi';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import LoadingButton from '../../../components/core/button/LoadingButton';

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY);

function MultipleDayAdvertisement({
  type,
  categories,
  open,
  handleClose,
  paymentMethods,
  setPaymentMethods,
  deleteCard,
  addAdvertisement,
}) {
  const userInfo = useSelector((state) => state.auth.userData);

  const [fromDate, setFromDate] = useState(null);
  const [toDate, setToDate] = useState(null);
  const [category, setCategory] = useState('');
  const [showCardPopup, setShowCardPopup] = useState(false);
  const [isStripeLoaded, setIsStripeLoaded] = useState(false);
  const [paymentIntent, setPaymentIntent] = useState(null);
  const [selectedPaymentMethod, setSelectedPaymentMethod] = useState('');
  const [addCardLoading, setAddCardLoading] = useState(false);
  const [submitPaymentLoading, setSubmitPaymentLoading] = useState(false);

  const formSchema = useMemo(
    () =>
      yup.object({
        offerName: yup
          .string()
          .required('Offer name is required')
          .max(60, 'Offer name must be maximum 60 characters long.'),
      }),
    []
  );

  const {
    control,
    formState: { errors, isDirty, isValid },
    handleSubmit,
    setValue,
    getValues,
    reset,
  } = useForm({
    resolver: yupResolver(formSchema),
    mode: 'onChange',
    reValidateMode: 'onChange',
    defaultValues: {
      offerName: '',
      processingFee: 0.5,
      addAdvertisementAmount: 0,
      amountDue: 0,
    },
  });

  const calculateAdvertisementAmount = () => {
    if (fromDate && toDate) {
      // +1  because start date not included
      const dayCount = toDate.diff(fromDate, 'day', true) + 1;

      // 1$ * days difference from from and to date
      setValue('addAdvertisementAmount', dayCount);

      // 0.5 is processing fee
      setValue('amountDue', dayCount + 0.5);
    }
  };

  useEffect(() => {
    calculateAdvertisementAmount();
  }, [fromDate, toDate]);

  const resetAllFields = () => {
    reset();
    setPaymentIntent(null);
    setFromDate(null);
    setToDate(null);
    setCategory('');
    setSelectedPaymentMethod('');
  };

  const addMultiDayAdvertisement = (data, event) => {
    event.preventDefault();
    setSubmitPaymentLoading(true);
    confirmPayment({
      amount: data.amountDue * 100,
      paymentMethodId: selectedPaymentMethod,
      customerId: userInfo.customerId,
      id: paymentIntent?.id,
    })
      .then(async (res) => {
        const { offerName, processingFee, addAdvertisementAmount } = data;
        const { provider: { _id: providerId } = {} } = userInfo;

        const paymentIntentId = res.data.paymentIntent.id;
        const reqData = {
          type: 'MULTIPLE_DAY',
          name: offerName,
          durationFrom: fromDate.format('YYYY-MM-DD HH:mm:ss.SSS'),
          durationTo: toDate.format('YYYY-MM-DD HH:mm:ss.SSS'),
          advertisementCost: addAdvertisementAmount,
          processingFee,
          currency: 'USD',
          paymentId: paymentIntentId,
          originalPrice: 0,
          discountedPrice: 0, // optional
          providerEmail: userInfo.email,
        };

        if (type === 'restaurant') {
          reqData.cuisineKey = category;
        } else {
          reqData.serviceKey = category;
        }

        await addAdvertisement(providerId, reqData, false).then((res) => {
          setSubmitPaymentLoading(false);
          if (res) resetAllFields();
        });
      })
      .catch((er) => {
        enqueueSnackbar(er?.message ?? 'Payment failed!', {
          variant: 'error',
        });
        setSubmitPaymentLoading(false);
      });
  };

  const addNewCard = () => {
    setAddCardLoading(true);
    const amountDue = getValues('amountDue');
    stripePromise
      .then(() => {
        const amount = amountDue * 100;
        if (paymentIntent?.amount && amount === paymentIntent.amount) {
          setAddCardLoading(false);
          setShowCardPopup(true);
        } else {
          createPaymentIntent({ amount, customerId: userInfo.customerId }).then(
            (res) => {
              if (res?.data) {
                setIsStripeLoaded(true);
                setPaymentIntent({ ...res.data });
                setShowCardPopup(true);
              } else {
                enqueueSnackbar(res?.message, { variant: 'error' });
              }
              setAddCardLoading(false);
            }
          );
        }
      })
      .catch((error) => {
        console.log('🚀 ~ addNewCard ~ error:', error);
      });
  };

  return (
    <Grid item xs={12} sm={10} marginTop={4}>
      <CheckoutForm
        stripe={stripePromise}
        open={showCardPopup}
        isStripeLoaded={isStripeLoaded}
        clientSecret={paymentIntent?.clientSecret}
        handleClose={(event, paymentMethod) => {
          if (paymentMethod && typeof paymentMethod === 'object') {
            setPaymentMethods([...paymentMethods, paymentMethod]);
            setSelectedPaymentMethod(paymentMethod.id);
          }
          setShowCardPopup(false);
        }}
      />

      <Dialog
        open={open}
        fullWidth={true}
        onClose={handleClose}
        className="dialog-merchant"
        aria-labelledby="responsive-dialog-title"
        PaperProps={{
          component: 'form',
          onSubmit: handleSubmit(addMultiDayAdvertisement),
        }}
      >
        <DialogTitle id="responsive-dialog-title">
          <Grid container>
            <Grid item xs={10}>
              <Typography variant="h4" color="#4F8D7D">
                Multi Days Advertisement
              </Typography>
            </Grid>
            <Grid item xs={2} textAlign="right">
              <Button variant="text" onClick={handleClose}>
                <CloseIcon />
              </Button>
            </Grid>
          </Grid>
        </DialogTitle>
        <DialogContent>
          <Grid container spacing={2} marginTop={0.1}>
            <Grid item xs={12} sm={12}>
              <TextInputControl
                control={control}
                error={Boolean(errors.offerName)}
                id="offerName"
                label="Offer Name"
                rules={{ required: true }}
                fullWidth
              />
            </Grid>
            <Grid item xs={12} sm={12}>
              <FormControl fullWidth={true}>
                <InputLabel id="category-select-label">
                  Category Name
                </InputLabel>
                <Select
                  labelId="category-select-label"
                  id="category-simple-select"
                  value={category}
                  label="Category Name"
                  onChange={(event) => {
                    setCategory(event.target.value);
                  }}
                >
                  {categories?.map(({ key, name }) => (
                    <MenuItem key={key} value={key}>
                      {name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={6}>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker
                  disablePast
                  format="MM/DD/YYYY"
                  label="From Date"
                  sx={{ width: '100%' }}
                  value={fromDate}
                  onChange={(value) => {
                    setFromDate(value);
                    setToDate(null);
                  }}
                />
              </LocalizationProvider>
            </Grid>
            <Grid item xs={12} sm={6}>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker
                  disablePast
                  format="MM/DD/YYYY"
                  label="To Date"
                  sx={{ width: '100%' }}
                  value={toDate}
                  onChange={(value) => {
                    setToDate(value);
                  }}
                  shouldDisableDate={(date) => {
                    return (
                      fromDate &&
                      !dayjs(date).isBetween(
                        fromDate.clone().add(6, 'days'),
                        fromDate.clone().subtract(1, 'day').add(1, 'month'),
                        'day',
                        '[]'
                      )
                    );
                  }}
                />
              </LocalizationProvider>
            </Grid>
          </Grid>
          <Grid container spacing={2} marginTop={0.1}>
            <Grid item xs={12} sm={6}>
              <TextInputControl
                control={control}
                disabled
                id="addAdvertisementAmount"
                label="Advertisement Amount"
                rules={{ required: true }}
                fullWidth
              />
            </Grid>

            <Grid item xs={12} sm={6}>
              <TextInputControl
                control={control}
                disabled
                id="processingFee"
                label="Processing Fees"
                rules={{ required: true }}
                fullWidth
              />
            </Grid>
          </Grid>

          <Grid item xs={12} sm={12} marginTop={2}>
            <TextInputControl
              control={control}
              disabled
              id="amountDue"
              label="Amount Due"
              rules={{ required: true }}
              fullWidth
              InputProps={{
                startAdornment: (
                  <InputAdornment>
                    <AttachMoneyIcon />
                  </InputAdornment>
                ),
              }}
            />
          </Grid>
          <Grid item xs={12} sm={12} marginTop={2} className="testing">
            <Box
              border={1}
              padding={2}
              borderColor="rgba(0, 0, 0, 0.23)"
              borderRadius={1}
            >
              <FormControl fullWidth sx={{ mb: 1 }}>
                <FormLabel sx={{ mb: 1 }}>Choose a Payment Method</FormLabel>
                <RadioGroup
                  aria-labelledby="payment-method"
                  name="payment-methods-group"
                >
                  {paymentMethods?.map(
                    ({
                      id,
                      brand,
                      holderName,
                      expMonth,
                      expYear,
                      cardNumber,
                    }) => (
                      <Grid container key={id}>
                        <Grid item xs={11}>
                          <FormControlLabel
                            value={id}
                            control={
                              <Radio
                                checked={selectedPaymentMethod === id}
                                onChange={(event) => {
                                  setSelectedPaymentMethod(event.target.value);
                                }}
                              />
                            }
                            label={brand}
                            sx={{
                              textTransform: 'capitalize',
                              marginRight: 1,
                            }}
                          />
                          <Typography variant="p" marginRight={2}>
                            ending in {cardNumber}
                          </Typography>
                          <Typography variant="p" marginRight={1}>
                            {holderName}
                          </Typography>
                          <Typography variant="p">
                            {expMonth?.toString()?.padStart(2, 0)} / {expYear}
                          </Typography>
                        </Grid>
                        <Grid item xs={1}>
                          <IconButton
                            onClick={() => {
                              deleteCard(id);
                              setSelectedPaymentMethod('');
                            }}
                          >
                            <DeleteIcon />
                          </IconButton>
                        </Grid>
                      </Grid>
                    )
                  )}
                </RadioGroup>
              </FormControl>
              <LoadingButton
                loading={addCardLoading}
                loadingPosition="start"
                startIcon={<AddIcon />}
                size="small"
                disabled={
                  !fromDate ||
                  !toDate ||
                  Object.keys(errors).length ||
                  !isDirty ||
                  !isValid ||
                  !category
                }
                onClick={addNewCard}
              >
                Add Card
              </LoadingButton>
            </Box>
          </Grid>
        </DialogContent>
        <DialogActions sx={{ margin: 1 }}>
          <LoadingButton
            type="submit"
            loading={submitPaymentLoading}
            size="small"
            disabled={
              !fromDate ||
              !toDate ||
              Object.keys(errors).length ||
              !isDirty ||
              !selectedPaymentMethod ||
              !isValid ||
              !category
            }
          >
            Submit a Payment
          </LoadingButton>
        </DialogActions>
      </Dialog>
    </Grid>
  );
}

export default MultipleDayAdvertisement;
